"use client";

import clsx from "clsx";
import { get } from "lodash";
import {
  FC,
  forwardRef,
  InputHTMLAttributes,
  LegacyRef,
  ReactNode,
  StyleHTMLAttributes,
  useState,
} from "react";

export type TextInputProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  required?: boolean;
  helpIcon?: boolean | ReactNode;
  badge?: ReactNode | string;
  hintText?: string;
  inputIcon?: ReactNode;
  helperText?: string;
  errorMessage?: string;
  disabled?: boolean;
  ref?: LegacyRef<HTMLInputElement>;
  classNames?: { container?: string; input?: string };
  styles?: {
    container?: StyleHTMLAttributes<HTMLDivElement>;
    input?: StyleHTMLAttributes<HTMLInputElement>;
  };
};
export const TextInput: FC<TextInputProps> = forwardRef(
  (
    {
      label,
      required,
      helpIcon,
      badge,
      hintText,
      inputIcon,
      helperText,
      errorMessage,
      disabled,
      classNames,
      styles,
      readOnly,
      ...props
    },
    ref
  ) => {
    const [value, setValue] = useState<string>("");
    const containerClassName = get(classNames, "container");
    const inputClassName = get(classNames, "input");
    const containerStyle = get(styles, "container");
    const inputStyle = get(styles, "input");

    const showLabelSection = !!label || !!required || !!helpIcon || !!badge;
    const showErrorSection = !!errorMessage || !!helperText;

    return (
      <div
        className={clsx("inline-flex flex-col gap-1.5", containerClassName)}
        style={containerStyle}
      >
        {showLabelSection && (
          <div className="inline-flex items-center gap-1">
            {!!label && (
              <span
                className={clsx(
                  "text-sm font-medium leading-5 duration-150",
                  disabled ? "text-[#B0B7C3]" : "text-white"
                )}
              >
                {label}
              </span>
            )}
            {!!required && (
              <span className="text-sm font-medium leading-5 text-[#DC2626]">
                *
              </span>
            )}
            {typeof helpIcon === "boolean"
              ? helpIcon && (
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1904_15163)">
                      <path
                        d="M6.0601 6.00005C6.21684 5.5545 6.5262 5.17879 6.9334 4.93948C7.3406 4.70016 7.81936 4.61268 8.28488 4.69253C8.7504 4.77238 9.17264 5.0144 9.47682 5.37574C9.78099 5.73708 9.94747 6.1944 9.94677 6.66672C9.94677 8.00005 7.94677 8.66672 7.94677 8.66672M8.00004 11.3334H8.00671M14.6667 8.00004C14.6667 11.6819 11.6819 14.6667 8.00004 14.6667C4.31814 14.6667 1.33337 11.6819 1.33337 8.00004C1.33337 4.31814 4.31814 1.33337 8.00004 1.33337C11.6819 1.33337 14.6667 4.31814 14.6667 8.00004Z"
                        stroke="#9CA3AF"
                        strokeWidth="1.33"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1904_15163">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                )
              : helpIcon}
            {typeof badge === "string"
              ? !!badge && (
                  <span className="px-2 py-0.5 bg-[#FFF] border border-[#E4E4E7] rounded-md text-xs font-medium leading-4 text-[#71717A]">
                    {badge}
                  </span>
                )
              : badge}
          </div>
        )}

        {!!hintText && (
          <div className="text-sm leading-4 text-[#8A94A6]">{hintText}</div>
        )}

        <div className="relative inline-flex">
          <input
            ref={ref}
            className={clsx(
              "px-3 py-2 outline outline-1 outline-transparent border border-[#CBD5E1] rounded-md leading-6 duration-150 flex-1 text-[#0F172A]",
              disabled || readOnly
                ? "bg-[#F2F2F2] placeholder:text-[#E2E8F0]"
                : "bg-[#FFF] placeholder:text-[#B0B7C3] hover:outline-[#2DC6CC] hover:border-[#2DC6CC] active:outline-[#2DC6CC]active:border-[#2DC6CC] focus:outline-[#2DC6CC] focus:border-[#2DC6CC]",
              inputClassName
            )}
            defaultValue={value}
            onChange={(e) => setValue(e.target.value)}
            disabled={disabled}
            readOnly={readOnly}
            style={inputStyle}
            {...props}
          />
        </div>

        {showErrorSection && (
          <div
            className={clsx(
              "text-sm leading-4 duration-150",
              disabled
                ? "text-[#DDDFE3]"
                : !!errorMessage
                ? "text-[#DC2626]"
                : !!value
                ? "text-[#64748B]"
                : "text-[#B0B7C3]",
              { invisible: !errorMessage && !helperText }
            )}
          >
            {errorMessage || helperText}
          </div>
        )}
      </div>
    );
  }
);
