import React from 'react';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { FaX } from "react-icons/fa6";
import { toast } from "react-toastify";
import { Button, TextArea, TextInput } from '@/components';
import { doSendRequestAccess } from "@/apis";
import { validateEmail } from "@/utils";

const schema = yup
  .object({
    firstName: yup.string().required("This field is required."),
    lastName: yup.string().optional(),
    email: yup.string().email().required("This field is required."),
    phone: yup.string().optional(),
    message: yup.string().optional(),
  })
  .required();

interface ContactSalesModalProps {
  isOpen: boolean;
  onClose: () => void;
}
export const ContactSalesModal: React.FC<ContactSalesModalProps> = ({ isOpen, onClose }) => {
  const [loading, setLoading] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset, // Add reset here
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: "",
    }
  });

  const handleClose = () => {
    reset(); // Reset the form
    onClose();
  };

  const onSubmit = async ({
    firstName,
    lastName,
    email,
    phone,
    message,
  }: any) => {
    if (!validateEmail(email)) {
      toast.error("Email is invalid");
      return;
    }

    try {
      setLoading(true);

      await doSendRequestAccess({
        first_name: firstName,
        last_name: lastName,
        email,
        phone,
        message,
      });
      
      toast.success("Your request is accepted!");
      onClose();
    } catch (error: any) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className={`fixed z-10 inset-0 overflow-y-auto ${isOpen ? '' : 'hidden'}`}>
      <div className="fixed inset-0 bg-black opacity-50"></div> {/* Add this line for background overlay */}
      <div className="flex items-center justify-center min-h-screen mx-4">
        <div className="bg-[#17181D] p-12 rounded-lg shadow-lg w-full max-w-[804px] relative"> {/* Add relative class */}
          <button onClick={handleClose} className="absolute top-4 right-4 text-white">
            <FaX size={12} />
          </button>
          <h2 className="text-xl font-bold mb-4">Contact Sales</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col sm:flex-row gap-4">
                <TextInput
                  classNames={{ container: "flex-1 text-white" }}
                  label="First Name"
                  {...register("firstName")}
                  errorMessage={errors.firstName?.message}
                  required
                />

                <TextInput
                  classNames={{ container: "flex-1" }}
                  label="Last Name (optional)"
                  {...register("lastName")}
                  errorMessage={errors.lastName?.message}
                />
              </div>

              <div className="flex flex-col sm:flex-row gap-4">
                <TextInput
                  classNames={{ container: "flex-1" }}
                  label="Work Email"
                  {...register("email")}
                  errorMessage={errors.firstName?.message}
                  required
                />

                <TextInput
                  classNames={{ container: "flex-1" }}
                  label="Phone (optional)"
                  {...register("phone")}
                  errorMessage={errors.lastName?.message}
                />
              </div>

              <TextArea
                label="Message"
                {...register("message")}
                errorMessage={errors.message?.message}
              />

              <div className="mt-4">
                <Button
                  className="w-full"
                  loading={loading}
                >
                  Submit
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
